import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
  I18N_DEFAULT_NAMESPACE,
  I18N_FALLBACK_LANGUAGE,
  I18N_LANGUAGE_CODES,
  I18N_NAMESPACES,
} from './constants';

const initI18nextClient = async (lng: string, resources: any) => {
  if (i18n.isInitialized) {
    return i18n;
  }

  await i18n.use(LanguageDetector).init({
    fallbackLng: I18N_FALLBACK_LANGUAGE,
    supportedLngs: Object.values(I18N_LANGUAGE_CODES),
    debug: true,
    load: 'currentOnly',
    preload: Object.values(I18N_LANGUAGE_CODES),
    resources: { [lng]: resources },
    ns: I18N_NAMESPACES,
    defaultNS: I18N_DEFAULT_NAMESPACE,
    interpolation: {
      escapeValue: false,
    },
    initAsync: false,
    detection: {
      order: ['cookie', 'navigator'],
      caches: ['cookie'],
      excludeCacheFor: ['cimode'],
    },
  });

  await i18n.loadNamespaces(I18N_NAMESPACES);

  return i18n;
};

export { i18n, initI18nextClient };
