import { onMount } from 'solid-js';
import { setI18n } from '~/store/i18n';
import { initI18nextClient } from './client';

/*
 * Makes the i18n object available on client side
 */
export function I18nProvider(props: { locales: any; language: string }) {
  onMount(async () => {
    try {
      const i18n = await initI18nextClient(props.language, props.locales);
      setI18n(i18n);
    } catch (err) {
      console.error('Failed to initialize i18next: ', err);
    }
  });

  return null;
}
